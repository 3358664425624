
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns27b148060d8f76ad8db98b773b084667en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '27b148060d8f76ad8db98b773b084667', flattenObject(ns27b148060d8f76ad8db98b773b084667en_US), true, true);
        
            import ns27b148060d8f76ad8db98b773b084667en from './en.yaml'
            i18n.addResourceBundle('en', '27b148060d8f76ad8db98b773b084667', flattenObject(ns27b148060d8f76ad8db98b773b084667en), true, true);
        
        export default '27b148060d8f76ad8db98b773b084667';
    