// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import Header from 'components/Header'
import VoucherValue from 'components/VoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'
import VideoThumbnail from 'components/VideoThumbnail'
import ExtraIncentive from 'components/ExtraIncentive'
import Sidebar from 'components/Sidebar'
import SuccessInfo from 'components/SuccessInfo'
import Modal from 'components/Modal'
import LeadGenForm from 'components/LeadGenForm/Step2'
import LeadGenContainer from 'components/LeadGenContainer'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default function Step2 (props: Props) {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [done, setDone] = React.useState(false)

    if (!state.lead || done) {
        return <Redirect to={`${props.location.pathname.replace('/step-2', '')}${props.location.search}`} push/>
    }

    return (
        <LeadGenContainer>
            <div className={styles.formContainer}>
                <Header />

                <LeadGenForm
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: true
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            </div>

            <Sidebar className={styles.sidebarContainer}>
                <VideoThumbnail
                    className={styles.videoThumbnail}
                    background={1}
                />
                <VoucherValue/>
                <CashbackAndRates small className={styles.cashbackAndRates}/>

                <ExtraIncentive className={styles.extraIncentive}/>
            </Sidebar>

            <Modal className="success-modal" isOpen={submitted} onCancel={() => setDone(true)}>
                <div className={styles.successModalContainer}>
                    <SuccessInfo/>
                </div>
            </Modal>

        </LeadGenContainer>
    )
}
