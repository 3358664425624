// @flow

export function makeCampaign (style: string) {
    return {
        videos: {
            sidebar: `https://s3.amazonaws.com/com.shift-marketing.videos.demo/upgrade-sale-luxury-2019-1/${style}.mp4`
        },
        // incentives: {
        //     manufacturer: {
        //         cashBack: 0,
        //         interestRate: 0,
        //     }
        // },
    }
}
