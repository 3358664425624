// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import MessageIncentive from 'components/MessageIncentive'
import Header from 'components/Header'
import VoucherValue from 'components/VoucherValue'
import VideoThumbnail from 'components/VideoThumbnail'
import CashbackAndRates from 'components/CashbackAndRates'
import LeadGenContainer from 'components/LeadGenContainer'
import Sidebar from 'components/Sidebar'
import LeadGenForm from 'components/LeadGenForm/Step1/Step1'

import styles from './styles.module.scss'

import { Store } from 'Store'
import { updateLead } from 'Actions'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-1', '')}${props.location.search}`} push/>
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-1', 'step-2')}${props.location.search}`} push/>
    }

    return (
        <LeadGenContainer>
            <div className={styles.formContainer}>
                <Header />

                <LeadGenForm
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            </div>

            <Sidebar className={styles.sidebarContainer}>
                <VideoThumbnail
                    className={styles.videoThumbnail}
                    background={0}
                />
                <MessageIncentive/>
                <VoucherValue/>
                <CashbackAndRates small className={styles.cashbackAndRates}/>
            </Sidebar>
        </LeadGenContainer>
    )
}
