// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        firstName: ?string,
        lastName: ?string,
        email: ?string,
        mobilePhone: ?string,
        currentVehicleYear: ?string,
        currentVehicleMake: ?string,
        currentVehicleModel: ?string,
    }
}

function Step1 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)

    return (
        <>
            {/* <div className={styles.car}>
                <img src={theme.car} alt='' />
            </div> */}

            <Steps step={1} description={t('description')} />

            <div className={styles.innerFormContainer}>
                <h3>{t('section.contactInformation')}</h3>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field
                              // error={props.errors.firstName || null}
                                defaultValue={props.values.firstName || null}
                                onChange={value => props.handleFieldChange('firstName', value)}
                                type='text'
                                label={t('label.firstName')}
                          />
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field
                              // error={props.errors.lastName || null}
                                defaultValue={props.values.lastName || null}
                                onChange={value => props.handleFieldChange('lastName', value)}
                                type='text'
                                label={t('label.lastName')}
                          />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field
                                // error={props.errors.email || null}
                                defaultValue={props.values.email || null}
                                onChange={value =>
                                    props.handleFieldChange('email', value)
                                }
                                type='email'
                                required={true}
                                label={t('label.emailAddress')}
                          />
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field
                              // error={props.errors.mobilePhone || null}
                                defaultValue={props.values.mobilePhone || null}
                                onChange={value =>
                                    props.handleFieldChange('mobilePhone', value)
                                }
                                type='phone'
                                label={t('label.mobilePhone')}
                                required={true}
                          />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <h3>{t('section.currentVehicleInfo')}</h3>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field
                              // error={props.errors.currentVehicleYear || null}
                                defaultValue={props.values.currentVehicleYear || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleYear', parseInt(value, 10))
                              }
                                type='text'
                                label={t('label.vehicleYear')}
                          />
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field
                              // error={props.errors.currentVehicleMake || null}
                                defaultValue={props.values.currentVehicleMake || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleMake', value)
                              }
                                type='text'
                                label={t('label.vehicleMake')}
                          />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.Field
                      // error={props.errors.currentVehicleModel || null}
                        defaultValue={props.values.currentVehicleModel || null}
                        onChange={value =>
                            props.handleFieldChange('currentVehicleModel', value)
                      }
                        type='text'
                        label={t('label.vehicleModel')}
                  />
                </Form.Row>
                <div className={styles.spacer}></div>
                <Form.Row>
                    <Form.Submit
                        isLoading={props.isLoading}
                        label={t('label.continueToStep2')}
                        style={{
                            border: `${theme.border.width} solid ${theme.border.color}`,
                            background: theme.background,
                            color: props.isLoading && theme.hiddeOnloading ? theme.background : theme.color
                        }}
                  />
                </Form.Row>
            </div>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step1))
