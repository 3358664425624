// @flow
import React, { Component } from 'react'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import checkMarkSvg from './images/CheckMark.svg'

type Props = {
    step: number,
    description?: string,
};

type StepProps = {
    t: Function,
    theme: { [string]: mixed },
    step: number,
    currentStep: number,
}

const Step = ({ t, theme, step, currentStep }: StepProps) => {
    const completed = currentStep > step
    const current = currentStep === step

    const classes = cn({ [styles.current]: current, [styles.completed]: completed })

    const isActive = current ? { color: theme.activeColor, fontWeight: 500 } : {}
    const checkmark = completed ? <img className={styles.checkmark} src={checkMarkSvg} alt='' /> : null

    return (
        <div className={styles.stepLabel}>
            {checkmark}
            <span className={classes} style={{ color: theme.inactiveColor, ...isActive }}>{t(`step${step}`)}</span>
        </div>
    )
}

class Steps extends Component<Props> {
    static defaultProps = {
        step: 1
    }

    render () {
        const { t, theme, step, description } = this.props

        return (
            <div className={cn(styles.container, this.props.className)}>
                {
                    description &&
                    <p className={styles.description}>
                        {description}
                    </p>
                }

                <div className={styles.stepLabels}>
                    {[1, 2, 3].map(stepNumber => <Step key={stepNumber} step={stepNumber} currentStep={step} t={t} theme={theme} />)}
                </div>

                <div
                    className={styles.bar}
                    style={{ backgroundColor: theme.stepsBarInactiveBg }}
                >
                    <div
                        className={ styles.barActiveBlock }
                        style={{ left: `${this.props.step / 3 * 100 - 33.33}%`, backgroundColor: theme.stepsBarActiveBg }}
                    ></div>
                </div>
            </div>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Steps))
