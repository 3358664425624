// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import RegisterVoucherValue from 'components/RegisterVoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'

function RegisterIncentives (props) {
    return (
        <div className={styles.inner}>
            <div className={styles.incentivesWrapper}>
                <RegisterVoucherValue />
                <CashbackAndRates />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterIncentives))
