// @flow
import * as React from 'react'

import { Store } from 'Store'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import messageSignature from './images/signature.png'

function MessageIncentive () {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)
    return (
        <div className={styles.message}>
            <div className={styles.messageTitle}>
                {t('message_title', {
                    campaign_id: state.campaign.id,
                    position: Boolean(state.campaign.contactInfo.position) ? state.campaign.contactInfo.position : 'undefined'})
                }
            </div>

            <p className={styles.messageParagraph}>
                {t('message_paragraph')}
            </p>

            <img className={styles.messageSignature} src={messageSignature} alt="" />

            <div className={styles.messageFooter}>
                <strong>{state.campaign.contactInfo.name}</strong>, {state.campaign.contactInfo.position && `${state.campaign.contactInfo.position},`} {state.campaign.client.name && `${state.campaign.client.name}`}
            </div>
        </div>
    )
}

export default MessageIncentive
