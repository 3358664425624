// @flow
import * as React from 'react'

import * as TWEEN from 'tween'

import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MaskedLayers from 'util/MaskedLayers'
import Draggable from 'util/Draggable'
import MediaQuery from 'react-responsive'

import RegistrationForm from 'components/RegistrationForm'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import MainLogo from '../MainLogo'
import SlideHandleLeft from './SlideHandleLeft'
import SlideHandleRight from './SlideHandleRight'
import { Store } from 'Store'

import type { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme } = props
    const { state } = React.useContext(Store)

    const bgOverlay = null

    React.useEffect(() => {
        if (theme.disableRegisterSlide) return

        setTimeout(() => {
            let carsMaskedLayers = new MaskedLayers(document.getElementsByClassName('js-register-cars-masked-layers')[0])
            let textMaskedLayers = null

            if (window.outerWidth > 767) {
                textMaskedLayers = new MaskedLayers(document.getElementsByClassName('js-register-text-masked-layers')[0])
            }

            window.slider = new Draggable(
                document.getElementsByClassName('js-register-slide')[0],
                document.getElementsByClassName('js-register-slider-handle')[0],
                (offsetX) => {
                    carsMaskedLayers.setCutLine(offsetX)

                    if (window.outerWidth > 767) {
                        textMaskedLayers && textMaskedLayers.setCutLine(offsetX)
                    }
                }
            )

            let coords = { x: window['outerWidth'] }
            new TWEEN.Tween(coords)
                .to({ x: document.body.clientWidth / 2 }, 8000)
                .delay(500)
                .easing(TWEEN.Easing.Elastic.Out)
                .onUpdate(function () {
                    window.slider.setPosition(this.x)
                })
                .onComplete(function () {
                    window.slider.showHandle()
                })
                .start()

            requestAnimationFrame(animate)

            function animate () {
                requestAnimationFrame(animate)
                TWEEN.update()
            }

            animate()
        }, 0)
    }, [theme.disableRegisterSlide])

    return (
        <div className={styles.mainContainer}>
            {!theme.disableRegisterSlide && (
                <div className="register-slider js-register-slide">
                    <div className="register-slider-handle js-register-slider-handle">
                        <div className="register-slider-handle-label __left">
                            <SlideHandleLeft arrowColor={theme.arrows.left.color} bgColor={theme.arrows.left.bg} />
                        </div>
                        <div className="register-slider-handle-label __right">
                            <SlideHandleRight arrowColor={theme.arrows.right.color} bgColor={theme.arrows.right.bg} />
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo logoColor={theme.colors.primary} titleColor='#000' title={state.campaign.client.name} />
                    </div>

                    <div className={styles.switcheroo}>
                        <div className={styles.registerForm} style={{ background: bgOverlay }}>
                            <div className="register-text-masked-layers js-register-text-masked-layers">
                                <h3 className="register-text-layer js-masked-layer __right" data-mask="right">
                                    <Trans data-mask="right" i18nKey="title">
                                        <span className="hide-text">Out with the old.</span> <strong>In with the new!</strong>
                                    </Trans>
                                </h3>
                                <h3 className="register-text-layer js-masked-layer __left" data-mask="left">
                                    <Trans data-mask="right" i18nKey="title">
                                        <span>Out with the old.</span> <strong className="hide-text">In with the new!</strong>
                                    </Trans>
                                </h3>
                            </div>

                            <div className={styles.formContainer}>
                                <RegistrationForm/>
                            </div>
                        </div>

                        <div className="register-cars-masked-layers js-register-cars-masked-layers">
                            <div className="register-cars-layer js-masked-layer" data-mask="right">
                                <MediaQuery minWidth={768}>
                                    <img src={theme.carsNew} alt="" />
                                </MediaQuery>
                                <MediaQuery maxWidth={767}>
                                    <img src={theme.carsNewMobile} alt="" />
                                </MediaQuery>
                            </div>

                            {!theme.disableRegisterSlide && (
                                <div className="register-cars-layer js-masked-layer" data-mask="left">
                                    <MediaQuery minWidth={768}>
                                        <img src={theme.carsOld} alt="" />
                                    </MediaQuery>
                                    <MediaQuery maxWidth={767}>
                                        <img src={theme.carsOldMobile} alt="" />
                                    </MediaQuery>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
