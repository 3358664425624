// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import styles from './styles.module.scss'

const DEFAULT_MILEAGE_OPTIONS = [5000, 15000, 30000, 50000, 70000, 90000, 100000]

type Props = WithFormProps | {
    values: {
        currentVehicleMileage: ?number,
        currentVehicleCondition: ?string,
        currentVehicleFinanceMethod: ?string,
        currentVehicleLeasePayment: ?string,
        currentVehicleLeasePaymentsLeft: ?string,
        currentVehicleFinancePayment: ?string,
        currentVehicleFinanceAmountOwing: ?string,
        storeLocationId: ?string,
        newVehicleMake: ?string,
        newVehicleModel: ?string,
        hotButtons: ?string,
        salesperson: ?string,
        timeline: ?string,
    }
}

function Step2 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    const makes = state.campaign.models.map(model => model.make).filter((value, index, self) => self.indexOf(value) === index)

    if (makes.length === 1 && props.values.newVehicleMake !== makes[0]) {
        props.handleFieldChange('newVehicleMake', makes[0])
    }

    const models = React.useMemo(() => (
        state.campaign.models.filter(model => model.make === props.values.newVehicleMake)
    ), [props.values.newVehicleMake, state.campaign.models])

    const defaultModel = React.useMemo(() => {
        return models.find(model => model.name === props.values.newVehicleModel && model.year === props.values.newVehicleModelYear)
    }, [models, props.values.newVehicleModel, props.values.newVehicleModelYear])

    if (state.campaign.locations.length === 1 && !props.values.storeLocationId) {
        props.handleFieldChange('storeLocationId', state.campaign.locations[0].id)
    }

    const salesReps = React.useMemo((): { [string]: string } => {
        const selectedStoreLocation = state.campaign.locations.find(location => location.id === props.values.storeLocationId)
        if (!selectedStoreLocation) return {}

        return {
            ...selectedStoreLocation.salesReps.reduce((salesReps, rep) => ({ [rep.name]: rep.name, ...salesReps }), {}),
            'none': t('field.salesperson.options.none.label')
        }
    }, [props.values.storeLocationId, state.campaign.locations, t])

    const defaultMileageOption = React.useMemo(() => {
        return props.values.currentVehicleMileage
            ? DEFAULT_MILEAGE_OPTIONS.reduce(function (prev, curr) {
                return (Math.abs(curr - props.values.currentVehicleMileage) < Math.abs(prev - props.values.currentVehicleMileage) ? curr : prev)
            })
            : ''
    }, [props.values.currentVehicleMileage])

    return (
        <>
            <Steps step={2} description={t('description')} />

            <div className={styles.innerFormContainer}>
                <h3>{t('currentVehicle.heading')}</h3>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field label={t('field.currentVehicleMileage.label')}>
                                <Form.Dropdown
                                    placeholder={t('field.currentVehicleMileage.placeholder')}
                                    options={DEFAULT_MILEAGE_OPTIONS}
                                    optionFormat={'field.currentVehicleMileage.options.%name%'}
                                    trans={t}
                                    onChange={value => props.handleFieldChange('currentVehicleMileage', value ? parseInt(value, 10) : '')}
                                    defaultValue={defaultMileageOption.toString()}
                                />
                            </Form.Field>
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field label={t('field.currentVehicleCondition.label')}>
                                <Form.Dropdown
                                    // error={props.errors.currentVehicleCondition || null}
                                    placeholder={t('field.currentVehicleCondition.placeholder')}
                                    options={['extraClean', 'clean', 'average', 'rough', 'extraRough']}
                                    optionFormat={'field.currentVehicleCondition.options.%name%'}
                                    trans={t}
                                    onChange={value => props.handleFieldChange('currentVehicleCondition', value)}
                                    defaultValue={props.values.currentVehicleCondition}
                                />
                            </Form.Field>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>

                <Form.Row>
                    <Form.Field label={t('field.currentVehicleFinanceMethod.label')} noBorder extraPadding>
                        <Form.Choices
                            // error={props.errors.currentVehicleFinanceMethod || null}
                            color={ theme.titleColor }
                            choices={['leased', 'financed', 'cash']}
                            formatLabel={value => t(`field.currentVehicleFinanceMethod.choice.${value}`)}
                            onChange={value => props.handleFieldChange('currentVehicleFinanceMethod', value)}
                            multiple={false}
                            trans={t}
                            value={props.values.currentVehicleFinanceMethod}
                        />
                    </Form.Field>
                </Form.Row>

                <Form.Panel hidden={props.values.currentVehicleFinanceMethod !== 'leased'}>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field
                                // error={props.errors.currentVehicleLeasePayment || null}
                                defaultValue={props.values.currentVehicleLeasePayment || null}
                                onChange={value => props.handleFieldChange('currentVehicleLeasePayment', value)}
                                type='currency'
                                locale={state.locale}
                                currency={state.currency}
                                label={t('field.currentVehicleLeasePayment.label')}
                                required={props.values.currentVehicleFinanceMethod === 'leased'}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field
                                // error={props.errors.currentVehicleLeasePaymentsLeft || null}
                                defaultValue={props.values.currentVehicleLeasePaymentsLeft || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleLeasePaymentsLeft', value)
                                }
                                type='number'
                                label={t('field.currentVehicleLeasePaymentsLeft.label')}
                                required={props.values.currentVehicleFinanceMethod === 'leased'}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Panel>

                <Form.Panel hidden={props.values.currentVehicleFinanceMethod !== 'financed'}>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field
                                // error={props.errors.currentVehicleFinancePayment || null}
                                defaultValue={props.values.currentVehicleFinancePayment || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleFinancePayment', value)
                                }
                                type='currency'
                                locale={state.locale}
                                currency={state.currency}
                                label={t('field.currentVehicleFinancePayment.label')}
                                required={props.values.currentVehicleFinanceMethod === 'financed'}
                            />
                        </Form.RowColumn>
                        <Form.RowColumn>
                            <Form.Field
                                // error={props.errors.currentVehicleFinanceAmountOwing || null}
                                defaultValue={props.values.currentVehicleFinanceAmountOwing || null}
                                onChange={value =>
                                    props.handleFieldChange('currentVehicleFinanceAmountOwing', value)
                                }
                                type='currency'
                                locale={state.locale}
                                currency={state.currency}
                                label={t('field.currentVehicleFinanceAmountOwing.label')}
                                required={props.values.currentVehicleFinanceMethod === 'financed'}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Panel>

                <h3>{t('newVehicle.heading')}</h3>

                {makes.length > 1
                    ? <Form.Row>
                        <Form.Field
                            label={t('field.newVehicleMake.label')}
                        >
                            <Form.Dropdown
                                // error={props.errors.newVehicleMake || null}
                                placeholder={t('field.newVehicleMake.placeholder')}
                                options={makes}
                                onChange={value => {
                                    props.handleFieldChange('newVehicleMake', value)
                                    props.handleFieldChange('newVehicleModelYear', '')
                                    props.handleFieldChange('newVehicleModel', '')
                                }}
                                defaultValue={props.values.newVehicleMake}
                            />
                        </Form.Field>
                    </Form.Row>
                    : null
                }

                <Form.Row>
                    <Form.Field
                        label={t('field.newVehicleModel.label')}
                    >
                        <Form.ModelPickerDropdown
                            // error={props.errors.newVehicleModel || null}
                            placeholder={t('field.newVehicleModel.placeholder')}
                            models={models}
                            defaultModel={defaultModel}
                            onChange={value => {
                                props.handleFieldChange('newVehicleModelYear', value?.year)
                                props.handleFieldChange('newVehicleModel', value?.name)
                            }}
                        />
                    </Form.Field>
                </Form.Row>

                <Form.Row>
                    <Form.Field label={t('field.hotButtons.label')} noBorder extraPadding>
                        <Form.Choices
                            // error={props.errors.hotButtons || null}
                            color={theme.titleColor}
                            choices={['safety', 'performance', 'dependability', 'appearance', 'comfort', 'economy']}
                            formatLabel={value => t(`field.hotButtons.choice.${value}`)}
                            multiple={true}
                            onChange={values => props.handleFieldChange('hotButtons', values)}
                            value={props.values.hotButtons || []}
                            trans={t}
                            maxChoices={2}
                            columns={3}
                            required
                            minRequired={2}
                        />
                    </Form.Field>
                </Form.Row>

                <Form.Row>
                    <Form.RowColumns>
                        <Form.RowColumn>
                            <Form.Field label={t('field.timeline.label')}>
                                <Form.Dropdown
                                    // error={props.errors.timeline || null}
                                    placeholder={t('field.timeline.placeholder')}
                                    options={['immediately', 'lessThanAWeek', 'aFewWeeks', 'aFewMonths']}
                                    optionFormat={'field.timeline.options.%name%'}
                                    trans={t}
                                    onChange={value => props.handleFieldChange('timeline', value)}
                                    defaultValue={props.values.timeline}
                                />
                            </Form.Field>
                        </Form.RowColumn>

                        <Form.RowColumn>
                            <Form.Field label={t('field.salesperson.label')}>
                                <Form.Dropdown
                                    // error={props.errors.salesperson || null}
                                    placeholder={t('field.salesperson.placeholder')}
                                    options={salesReps}
                                    onChange={value => props.handleFieldChange('salesperson', value)}
                                    defaultValue={props.values.salesperson}
                                />
                            </Form.Field>
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
                <div className={styles.spacer}></div>
                <Form.Row>
                    <Form.Submit
                        isLoading={props.isLoading}
                        label={t('submit.label')}
                        style={{
                            border: `${theme.border.width} solid ${theme.border.color}`,
                            background: theme.background,
                            color: props.isLoading && theme.hiddeOnloading ? theme.background : theme.color
                        }}
                    />
                </Form.Row>
            </div>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step2))
