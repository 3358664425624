// @flow
import React from 'react'

import styles from './styles.module.scss'

function CampaignNotFound (props) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  )
}

export default CampaignNotFound
